import React, { useLayoutEffect } from 'react'
import { RouteProps } from 'react-router-dom'

import FooterForm from '../footer-form/Container'
import Map from '../map/Container'
import Meals from '../meals/Container'
import Plans from '../plans/Container'
import SEO from '../seo'
import DinnerHero from './hero/Container'
import HowItWorks from './how-it-works/Container'
import LoveMealpal from './love-mealpal/Container'
import SupportRestaurants from './support-restaurants/Container'

type Props = RouteProps<'/suburb'>

const SuburbContainer: React.FC<Props> = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SEO title="Dinner" />
      <DinnerHero />
      <HowItWorks />
      <SupportRestaurants />
      <LoveMealpal />
      <Meals isDelivery={false} />
      <Map />
      <Plans />
      <FooterForm />
    </>
  )
}

export default SuburbContainer
