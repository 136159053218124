import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import useHomepage from '../../hooks/useHomepage'
import Form from '../form/Form'
import './footer-form.less'

type Props = {
  position?: string
}

const getFormPositionName = (
  onNyLunchPage: boolean,
  onSfLunchPage: boolean,
  onNyDinnerPage: boolean,
  onMealPassPage: boolean,
  position?: string,
  productOffering?: string,
) => {
  if (onMealPassPage) {
    return 'mealpass'
  }

  if (onNyLunchPage) {
    return 'nylunch-footer'
  }

  if (onSfLunchPage) {
    return 'sflunch-footer'
  }

  if (onNyDinnerPage) {
    return 'nydinner-footer'
  }

  return position || `${productOffering}-footer`
}

const FooterFormContainer = ({ position }: Props) => {
  const { t } = useTranslation()
  const { productOffering, cities, onNyLunchPage, onSfLunchPage, onNyDinnerPage, onMealPassPage } = useHomepage()
  const formPosition = getFormPositionName(
    onNyLunchPage,
    onSfLunchPage,
    onNyDinnerPage,
    onMealPassPage,
    position,
    productOffering,
  )
  return (
    <div className="footer-form">
      <Form customClass="form--footer" action={t("See what's near you")} position={formPosition} cities={cities} />
    </div>
  )
}

export default FooterFormContainer
