import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'

import { SCROLL_ANIMATION_DEFAULT_PROPS } from '../../../constants'
import useHomepage from '../../../hooks/useHomepage'
import UtmContent from '../../../models/UtmContent'
import { AnalyticsService, UrlService } from '../../../services'
import ScrollAnimation from '../../scroll-animation/Container'
import './support-restaurants.less'

const SupportRestaurants = () => {
  const { t } = useTranslation()
  const { activeCityName, showModal, launchConfirmationRequired } = useHomepage()
  const [url, setUrl] = useState('')

  const onClickHandler = (e: React.MouseEvent) => {
    if (launchConfirmationRequired) {
      e.preventDefault()

      return showModal({ name: 'get-access-form' })
    }

    AnalyticsService.trackEvent({
      action: 'Click',
      category: 'Homepage',
      label: 'Support Restaurants Near You',
    })
  }

  useEffect(() => {
    const utmContent = new UtmContent({ position: 'support-restaurants' }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [])

  return (
    <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
      <div className="support-restaurants">
        <div className="support-restaurants__image" />
        <div className="support-restaurants__content">
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="support-restaurants__title">{t('Eat Well, Do Good')}</div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="support-restaurants__description">
              {t('Other third party platforms charge restaurants large fees.')}
              &nbsp;
              <span>{t('MealPal enables restaurants to optimize operations and increase profit.')}</span>
            </div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <a href={url} onClick={onClickHandler} className="support-restaurants__action red-cta">
              {t('Support restaurants near you')}
            </a>
          </ScrollAnimation>
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default SupportRestaurants
