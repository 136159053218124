import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import {
  HERO_CAROUSEL_ANIMATION_DELAY,
  MOBILE_VIEW_BREAKPOINT,
  SCROLL_ANIMATION_DEFAULT_PROPS,
} from '../../../constants'
import HeroImg from '../../../images/dinner-hero.jpg'
import Form from '../../form/Form'
import ScrollAnimation from '../../scroll-animation/Container'
import './hero.less'

const HeroContainer = () => {
  const isMobile = typeof window != 'undefined' && window.innerWidth < MOBILE_VIEW_BREAKPOINT
  const { t } = useTranslation()

  return (
    <div className="dinner-hero">
      <div className="dinner-hero__image-wrapper">
        <ScrollAnimation
          {...SCROLL_ANIMATION_DEFAULT_PROPS}
          animateIn={isMobile ? 'fadeIn' : 'fadeInLeftHero'}
          delay={isMobile ? 0 : HERO_CAROUSEL_ANIMATION_DELAY}
        >
          <div
            className="dinner-hero__image"
            style={{
              background: `url(${HeroImg}) no-repeat`,
            }}
          />
        </ScrollAnimation>
      </div>
      <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} animateIn={isMobile ? 'fadeInUpForm' : 'defaultAnimation'}>
        <div className="dinner-hero__form">
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} animateIn={isMobile ? 'defaultAnimation' : 'fadeIn'}>
            <div className="dinner-hero__title">{t('Dinner, Solved.')}</div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} animateIn={isMobile ? 'defaultAnimation' : 'fadeInUp'}>
            <div className="dinner-hero__subtitle">
              {' '}
              {t('Save up to 40% on takeout dinner while supporting restaurants in your community.')}{' '}
            </div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} animateIn={isMobile ? 'defaultAnimation' : 'fadeInLeft'}>
            <Form customClass="form--hero" position="dinner-hero" cities={[]} />
          </ScrollAnimation>
        </div>
      </ScrollAnimation>
    </div>
  )
}

export default HeroContainer
