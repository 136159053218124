import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import { SCROLL_ANIMATION_DEFAULT_PROPS } from '../../../constants'
import HeartImage from '../../../images/heart-icon.svg'
import ScrollAnimation from '../../scroll-animation/Container'
import './love-mealpal.less'

const LoveMealpal = () => {
  const { t } = useTranslation()

  return (
    <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
      <div className="love-mp">
        <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
          <div className="love-mp__title">
            {t('Restaurants')} <img src={HeartImage} /> MealPal
          </div>
        </ScrollAnimation>
        <div className="love-mp__body">
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="love-mp__column">
              <div className="love-mp__subtitle">10,000+</div>
              <div className="love-mp__description">{t('restaurant partners worldwide')}</div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} delay={200}>
            <div className="love-mp__column">
              <div className="love-mp__subtitle">58%</div>
              <div className="love-mp__description">{t('average increase in restaurant profit')}</div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} delay={400}>
            <div className="love-mp__column">
              <div className="love-mp__subtitle">$200,000,000+</div>
              <div className="love-mp__description">{t('incremental revenue driven by MealPal')}</div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default LoveMealpal
