import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'

import { SCROLL_ANIMATION_DEFAULT_PROPS } from '../../../constants'
import useHomepage from '../../../hooks/useHomepage'
import Image1 from '../../../images/how-it-works-icon-4.svg'
import Image2 from '../../../images/how-it-works-icon-5.svg'
import Image3 from '../../../images/how-it-works-icon-6.svg'
import UtmContent from '../../../models/UtmContent'
import { AnalyticsService, UrlService } from '../../../services'
import ScrollAnimation from '../../scroll-animation/Container'
import './how-it-works.less'

const HowItWorksContainer = () => {
  const { t } = useTranslation()
  const { activeCityName, showModal, launchConfirmationRequired } = useHomepage()
  const [url, setUrl] = useState('')

  const onClickHandler = (e: React.MouseEvent) => {
    if (launchConfirmationRequired) {
      e.preventDefault()

      return showModal({ name: 'get-access-form' })
    }

    AnalyticsService.trackEvent({
      action: 'Click',
      category: 'Homepage',
      label: 'Support Restaurants Near You',
    })
  }

  useEffect(() => {
    const utmContent = new UtmContent({ position: 'dinner-how-it-works' }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [])

  return (
    <div className="dinner-hiw">
      <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
        <div className="dinner-hiw__title">{t('How MealPal Works')}</div>
        <div className="dinner-hiw__details">
          {t('Order for yourself or the whole family. MealPal is smart, affordable and convenient')}
        </div>
      </ScrollAnimation>
      <div className="dinner-hiw__body">
        <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
          <div className="dinner-hiw__column">
            <div className="dinner-hiw__icon">
              <img src={Image1} />
            </div>
            <div className="dinner-hiw__subtitle">{t('Reserve')}</div>
            <div className="dinner-hiw__description">{t('Choose from hundreds of meals each week.')}</div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} delay={200}>
          <div className="dinner-hiw__column">
            <div className="dinner-hiw__icon">
              <img src={Image2} />
            </div>
            <div className="dinner-hiw__subtitle">{t('Pick-up')}</div>
            <div className="dinner-hiw__description">
              {t('Skip the wait. Your meals will be ready when you arrive')}
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} delay={400}>
          <div className="dinner-hiw__column">
            <div className="dinner-hiw__icon">
              <img src={Image3} />
            </div>
            <div className="dinner-hiw__subtitle">{t('Enjoy')}</div>
            <div className="dinner-hiw__description">{t('Save money at the best restaurants in your town.')}</div>
          </div>
        </ScrollAnimation>
      </div>
      <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
        <div className="dinner-hiw__action">
          <a className="red-cta" href={url} onClick={onClickHandler}>
            {t('Start saving on dinner today')}
          </a>
        </div>
      </ScrollAnimation>
    </div>
  )
}

export default HowItWorksContainer
